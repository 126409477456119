import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";

function Lists() {
  const [loading, setLoading] = useState(true);
  const [entries, setEntries] = useState([]);
  const [word, setWord] = useState("");

  // var token = null;
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    handleData();
  }, []);

  const handleData = async () => {
    const token = await getAccessTokenSilently();
    console.log(token);

    await axios({
      method: "get",
      url: process.env.REACT_APP_API_URL + "/words",
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      const entries = res.data;
      setEntries(entries);
      setLoading(false);
    });
  };

  const handleChange = (event) => {
    setWord(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = await getAccessTokenSilently();
    console.log(token);

    const entry = {
      word: word,
    };

    await axios({
      method: "post",
      url: process.env.REACT_APP_API_URL + "/words",
      data: { word: entry.word },
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log(res);
        console.log(res.data);
      })
      .then(setWord(""))
      .then(() => handleData());
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center mt-5 mb-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div>
      <Form onSubmit={handleSubmit} className="mb-3">
        <InputGroup>
          <Form.Control
            type="text"
            name="word"
            value={word}
            onChange={handleChange}
          />
          <Button type="submit">Add</Button>
        </InputGroup>
      </Form>
      <ListGroup>
        {entries.map((entry) => (
          <ListGroup.Item key={entry._id}>{entry.word}</ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
}

export default Lists;
