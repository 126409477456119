import Head from "./components/nav/head";
import Foot from "./components/nav/foot";
import Lists from "./components/lists";
import LoginButton from "./components/nav/loginButton";
import LogoutButton from "./components/nav/logoutButton";
import Profile from "./components/nav/profile";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

function App() {
  return (
    <main>
      <Container className="p-5 bg-light">
        <Row className="m-1 mb-3">
          <LoginButton />
          <LogoutButton />
          <Profile />
        </Row>
        <Row className="m-1 mb-3">
          <Head />
        </Row>
        <Row className="m-1 mb-3">
          <Lists />
        </Row>
        <Row className="m-1">
          <Foot />
        </Row>
      </Container>
    </main>
  );
}

export default App;
